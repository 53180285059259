import React, { Component } from 'react'
import { Route, Navigate, Routes } from 'react-router-dom'
import { IntlProvider } from 'react-intl-redux'
import PropTypes from 'prop-types'
import moment from 'moment'

import { LOCAL_STORAGE_KEYS } from 'constants/account'
import { PERMISSION_KEYS } from 'constants/account/permissions'
import {
  INVENTORY_URLS,
  LAUNCH_ROOT_URL,
  LISTING_BUILDER_BASE,
  PPC_SYNC_RETURN_URL,
  SALES_ANALYTICS_URLS,
  SELLING_PARTNER_SYNC_CALLBACK_URL,
  RANK_TRACKER_V2_BASE,
  SHARE_OF_VOICE_URL,
  ADVERTISING_URLS,
  KEYWORD_LISTS_URL,
  PRODUCT_COSTS_AND_SETTINGS_URL,
  DEVELOPER_URL,
  COMPETITIVE_INTELLIGENCE_URL
} from 'constants/routes'
import { LIMIT_KEYS } from 'constants/feature_limits'
import { Features } from 'constants/feature_names'

import { getMatchingRedirectRoute } from 'helpers/redirects'
import { hasAccess } from 'helpers/permissions'
import { randomString } from 'helpers/strings'
import { setLocalData } from 'helpers/storage'
import { hasFeatureLimit } from 'helpers/feature_limits'
import { isFeatureEnabled } from 'helpers/features'

import { AuthRoute } from 'components/common/routes/AuthRoute/AuthRoute'
import { UnAuthRoute } from 'components/common/routes/UnAuthRoute/UnAuthRoute'
import { AdminRoute } from 'components/common/routes/AdminRoute/AdminRoute'
import { DynamicRoute } from 'components/common/routes/DynamicRoute/DynamicRoute'
import { AccessRoute } from 'components/common/routes/AccessRoute'
import LoadingPage from 'components/common/LoadingPage'
import { FourZeroFourPage } from 'components/FourZeroFourPage'

import { history } from './store'

export class RoutesComponent extends Component {
  componentDidMount() {
    // this is the default title as in the index.html file
    // resetting the title on unmount ensures we don't leave the title hanging for
    // pages which haven't been updated yet
    history.listen(() => {
      document.title = 'Jungle Scout'
    })
  }

  render() {
    const { props } = this
    const {
      globalData: { user },
      globalData
    } = props

    const baseRedirectRoute = '/dashboard'

    const redirectRoute = getMatchingRedirectRoute()
    if (redirectRoute) {
      window.location.href = redirectRoute
      return <LoadingPage />
    }

    const searchParams = new URLSearchParams(window.location.search)
    const amazonRedirectUrl = searchParams.get('amazon_callback_uri')

    if (user?.id && amazonRedirectUrl) {
      const amazonState = searchParams.get('amazon_state')

      const stateParam = randomString(20)

      const state = {
        stateParam,
        expires: moment(Date.now())
          .add(1, 'h')
          .toString()
      }

      setLocalData(
        LOCAL_STORAGE_KEYS.MWS_SYNC_REDIRECT_STATE,
        JSON.stringify(state)
      )

      const redirectUri =
        window.location.origin + SELLING_PARTNER_SYNC_CALLBACK_URL

      window.location.href = `${amazonRedirectUrl}?redirect_uri=${redirectUri}&amazon_state=${amazonState}&state=${stateParam}`
      return <LoadingPage />
    }

    const hasSupplierAccess = hasAccess(
      user.permissions,
      PERMISSION_KEYS.SUPPLIERS
    )
    const hasAcademyAccess = hasAccess(
      user.permissions,
      PERMISSION_KEYS.ACADEMY
    )
    const hasKeywordAccess = hasAccess(
      user.permissions,
      PERMISSION_KEYS.KEYWORDS
    )
    const hasFindProductsAccess = hasAccess(
      user.permissions,
      PERMISSION_KEYS.FIND_PRODUCTS
    )
    const hasToolboxAccess = hasAccess(
      user.permissions,
      PERMISSION_KEYS.TOOLBOX
    )
    const hasReviewAnalysisAccess = hasFeatureLimit(
      globalData?.featureLimits,
      LIMIT_KEYS.aiFeatures
    )
    const hasFbaReimbursementsAccess = hasAccess(
      user.permissions,
      PERMISSION_KEYS.FBA_REIMBURSEMENT
    )

    const hasLaunchAccess = hasAccess(user.permissions, PERMISSION_KEYS.LAUNCH)

    const hasDeveloperAccess = hasAccess(
      user.permissions,
      PERMISSION_KEYS.PUBLIC_API
    )

    const isSellerKeySyncFlowUpgrade = isFeatureEnabled(
      Features.ACCOUNT_SELLER_KEYS_SYNC_FLOW_UPGRADE,
      globalData?.flagData
    )

    const isOpportunityFinderV2Enabled = isFeatureEnabled(
      Features.OPPORTUNITY_FINDER_V2,
      globalData?.flagData
    )

    return (
      <IntlProvider>
        <Routes>
          <Route path="*" element={<Navigate to={baseRedirectRoute} />} />
          <Route
            path="/grader/*"
            element={<Navigate to="/toolbox/review-analysis" replace />}
          />
          <Route
            path="/toolbox/listing-grader"
            element={<Navigate to="/toolbox/review-analysis" replace />}
          />
          <Route path="/onboard" element={<Navigate to="/setup" replace />} />
          <Route
            path="/registrations/*"
            element={
              <DynamicRoute
                load={() =>
                  import('./containers/registrations/RegistrationPage')
                }
                {...props}
              />
            }
          />
          <Route
            path="/checkout/*"
            element={
              <DynamicRoute
                load={() =>
                  import('./containers/registrations/RegistrationPage')
                }
                {...props}
              />
            }
          />
          <Route
            path="/reset-password/*"
            Component={() => {
              window.location.href = `${process.env.REACT_APP_CLIENT_LOGIN}/reset-password`
              return null
            }}
          />
          <Route
            path="/login"
            Component={() => {
              window.location.href = process.env.REACT_APP_CLIENT_LOGIN
              return null
            }}
          />
          <Route
            path="/add-user/*"
            element={
              <UnAuthRoute
                load={() =>
                  import('./containers/additional_users/AdditionalUsersPage')
                }
                {...props}
              />
            }
          />
          <Route
            path="/extension-uninstall"
            element={
              <DynamicRoute
                load={() =>
                  import('./containers/extension/UninstallPage/UninstallPage')
                }
                {...props}
              />
            }
          />
          <Route
            path="/redirect"
            element={
              <AuthRoute
                load={() => import('./components/RedirectPage')}
                globalData={globalData}
              />
            }
          />
          <Route
            path="/setup"
            element={
              <AuthRoute
                load={() =>
                  import('./components/setup_wizard_v2/SetupWizardPage')
                }
                globalData={globalData}
                reducers={[() => import('reducers/review_automation')]}
              />
            }
          />
          <Route
            path="/extension-onboarding"
            element={
              <AuthRoute
                load={() =>
                  import(
                    './components/extension_onboarding/ExtensionOnboardingPage'
                  )
                }
                globalData={globalData}
              />
            }
          />
          <Route
            path="/cancel"
            element={
              <AuthRoute
                load={() =>
                  import('./containers/account/CancelSubscriptionPage')
                }
                globalData={globalData}
              />
            }
          />
          <Route
            path="/cancel-confirm"
            element={
              <AuthRoute
                load={() =>
                  import('./containers/account/ConfirmCancellationPage')
                }
                globalData={globalData}
              />
            }
          />
          <Route
            path="/dashboard/*"
            element={
              <AuthRoute
                load={() => import('./components/dashboard/DashboardPage')}
                globalData={globalData}
              />
            }
          />
          <Route
            path="/tracker"
            element={
              <AccessRoute
                load={() =>
                  import('./components/product_tracker/TrackerPage/TrackerPage')
                }
                globalData={globalData}
                hasAccess={hasFindProductsAccess}
              />
            }
          />
          <Route
            path="/database"
            element={
              <AccessRoute
                load={() =>
                  import('./components/product_database/ProductDatabasePage')
                }
                hasAccess={hasFindProductsAccess}
                noMatchProp
                {...props}
              />
            }
          />
          <Route
            path="/supplier/supplier-tracker/*"
            element={
              <AccessRoute
                load={() =>
                  import(
                    './components/suppliers/supplier_tracker/SupplierTrackerPage'
                  )
                }
                hasAccess={hasSupplierAccess}
                {...props}
              />
            }
          />
          <Route
            path="/supplier/*"
            element={
              <AccessRoute
                load={() =>
                  import(
                    './components/suppliers/supplier_database/SuppliersSearchPage'
                  )
                }
                hasAccess={hasSupplierAccess}
                globalData={globalData}
              />
            }
          />
          <Route
            path="/opportunity-finder"
            element={
              <AccessRoute
                load={() =>
                  isOpportunityFinderV2Enabled
                    ? import(
                        './components/opportunity_finder_v2/OpportunityFinderPage'
                      )
                    : import(
                        './containers/opportunity_finder/OpportunityFinderPage'
                      )
                }
                hasAccess={hasFindProductsAccess}
                globalData={globalData}
                noMatchProp
              />
            }
          />
          <Route
            path={`${COMPETITIVE_INTELLIGENCE_URL}/*`}
            element={
              <AccessRoute
                load={() =>
                  import(
                    './components/competitive_intelligence/CompetitiveIntelligenceRouter'
                  )
                }
                hasAccess={hasAccess(
                  user.permissions,
                  PERMISSION_KEYS.COMPETITIVE_INTELLIGENCE
                )}
                globalData={globalData}
              />
            }
          />
          <Route
            path="/category-trends"
            element={
              <AccessRoute
                load={() =>
                  import(
                    './components/category_trends/CategoryTrendsPage/CategoryTrendsPage'
                  )
                }
                hasAccess={hasFindProductsAccess}
                globalData={globalData}
                noMatchProp
              />
            }
          />
          <Route
            path="/keyword/*"
            element={
              <AccessRoute
                load={() =>
                  import('./components/keyword_scout/KeywordScoutPage')
                }
                hasAccess={hasKeywordAccess}
                globalData={globalData}
                noMatchProp
              />
            }
          />
          <Route
            path={`${KEYWORD_LISTS_URL}/*`}
            element={
              <AccessRoute
                load={() =>
                  import('./components/keyword_lists/KeywordListsPage')
                }
                hasAccess={hasKeywordAccess}
                globalData={globalData}
              />
            }
          />
          <Route
            path={`${LISTING_BUILDER_BASE}/*`}
            element={
              <AccessRoute
                load={() =>
                  import('./components/listing_builder/ListingBuilderRouter')
                }
                hasAccess={hasKeywordAccess}
                globalData={globalData}
              />
            }
          />
          <Route
            path={`${RANK_TRACKER_V2_BASE}/*`}
            element={
              <AuthRoute
                load={() =>
                  import('./components/rank_tracker/RankTrackerRouter')
                }
                globalData={globalData}
              />
            }
          />
          <Route
            path="/academy/*"
            element={
              <AccessRoute
                load={() => import('./containers/academy/AcademyRouter')}
                globalData={globalData}
                hasAccess={hasAcademyAccess}
                reducers={[() => import('reducers/academy')]}
              />
            }
          />
          <Route
            path={`${DEVELOPER_URL}/*`}
            element={
              <AccessRoute
                load={() => import('./components/developer/DeveloperPage')}
                globalData={globalData}
                hasAccess={hasDeveloperAccess}
              />
            }
          />
          <Route
            path="/extension"
            element={
              <AuthRoute
                load={() => import('./containers/extension/ExtensionPage')}
                globalData={globalData}
              />
            }
          />
          <Route
            path="/account/*"
            element={
              <AuthRoute
                load={() => import('./containers/account/AccountPage')}
                globalData={globalData}
                {...props}
              />
            }
          />
          <Route
            path={`${SHARE_OF_VOICE_URL}/*`}
            element={
              <AuthRoute
                load={() =>
                  import('./components/share_of_voice_orange/ShareOfVoicePage')
                }
                globalData={globalData}
              />
            }
          />
          <Route
            path="/admin/*"
            element={
              <AdminRoute
                load={() => import('./containers/admin/AdminParent')}
                reducers={[() => import('reducers/admin')]}
                {...props}
              />
            }
          />
          <Route
            path={`${LAUNCH_ROOT_URL}/*`}
            element={
              <AccessRoute
                load={() => import('./containers/launch/LaunchRouter')}
                globalData={globalData}
                hasAccess={hasLaunchAccess}
              />
            }
          />
          <Route
            path={`${PRODUCT_COSTS_AND_SETTINGS_URL}/*`}
            element={
              <AccessRoute
                load={() =>
                  import(
                    './components/product_costs_and_settings/ProductCostsAndSettingsPage'
                  )
                }
                globalData={globalData}
                hasAccess={hasAccess(
                  user.permissions,
                  PERMISSION_KEYS.MY_PRODUCTS
                )}
                sellerFeature
                {...props}
              />
            }
          />
          <Route
            path={`${SALES_ANALYTICS_URLS.expenses}/*`}
            element={
              <AccessRoute
                load={() =>
                  import('./containers/sales_analytics/expenses/ExpensesPage')
                }
                hasAccess={hasAccess(
                  user.permissions,
                  PERMISSION_KEYS.SALES_ANALYTICS
                )}
                sellerFeature
                {...props}
              />
            }
          />
          <Route
            path={`${SALES_ANALYTICS_URLS.profitAndLoss}/*`}
            element={
              <AccessRoute
                load={() =>
                  import(
                    './containers/sales_analytics/profit_and_loss/ProfitAndLossPage'
                  )
                }
                hasAccess={hasAccess(
                  user.permissions,
                  PERMISSION_KEYS.SALES_ANALYTICS
                )}
                sellerFeature
                {...props}
              />
            }
          />
          <Route
            path={`${SALES_ANALYTICS_URLS.profitOverview}/*`}
            element={
              <AccessRoute
                load={() =>
                  import(
                    './components/sales_analytics/profit_overview_v2/ProfitOverviewPage'
                  )
                }
                hasAccess={hasAccess(
                  user.permissions,
                  PERMISSION_KEYS.SALES_ANALYTICS
                )}
                sellerFeature
                noMatchProp
                {...props}
              />
            }
          />
          <Route
            path={`${ADVERTISING_URLS.analytics}/*`}
            element={
              <AccessRoute
                load={() =>
                  import(
                    './components/advertisement_analytics/AdvertisementPage'
                  )
                }
                hasAccess={hasAccess(
                  user.permissions,
                  PERMISSION_KEYS.ADVERTISING
                )}
                sellerFeature
                noMatchProp
                {...props}
              />
            }
          />
          <Route
            path={`${INVENTORY_URLS.inventoryForecast}/*`}
            element={
              <AccessRoute
                load={() =>
                  import(
                    './containers/inventory/inventory_forecast/InventoryForecastPage'
                  )
                }
                hasAccess={hasAccess(
                  user.permissions,
                  PERMISSION_KEYS.MY_PRODUCTS
                )}
                sellerFeature
                {...props}
              />
            }
          />
          <Route
            path={`${SELLING_PARTNER_SYNC_CALLBACK_URL}/*`}
            element={
              <AuthRoute
                load={() =>
                  isSellerKeySyncFlowUpgrade
                    ? import(
                        './components/account/SellerAccountSyncFlow/SellerSyncRedirectPage'
                      )
                    : import('./containers/mws_ppc_sync/MwsSyncRedirectPage')
                }
                globalData={globalData}
              />
            }
          />
          <Route
            path={`${PPC_SYNC_RETURN_URL}/*`}
            element={
              <AuthRoute
                load={() =>
                  isSellerKeySyncFlowUpgrade
                    ? import(
                        './components/account/SellerAccountSyncFlow/SellerSetupRedirectPage'
                      )
                    : import(
                        './containers/account/PpcSyncRedirectPage/PpcSyncRedirectPage'
                      )
                }
                {...props}
              />
            }
          />
          <Route
            path="/unsubscribe_email/:type/:unsub_hash/*"
            element={
              <DynamicRoute
                load={() =>
                  import(
                    './containers/email_unsubscribe_link/EmailUnsubscribeLinkPage'
                  )
                }
              />
            }
          />
          <Route
            path="/toolbox/sales-estimator"
            element={
              <AccessRoute
                load={() =>
                  import('./components/sales_estimator/SalesEstimatorPage')
                }
                hasAccess={hasToolboxAccess}
                globalData={globalData}
              />
            }
          />
          <Route
            path="/toolbox/review-analysis"
            element={
              <AccessRoute
                load={() =>
                  import('./components/review_analysis/ReviewAnalysisPage')
                }
                hasAccess={hasToolboxAccess && hasReviewAnalysisAccess}
                globalData={globalData}
              />
            }
          />
          <Route
            path="/inventory-reimbursements"
            element={
              <AccessRoute
                load={() =>
                  import(
                    './containers/inventory_reimbursements/InventoryReimbursementsPage'
                  )
                }
                hasAccess={hasFbaReimbursementsAccess}
                globalData={globalData}
              />
            }
          />
          {/* NOTE if you're addding anything new, make sure to add it above this 404 page, the 404 is a catch all and should be the last thing */}
          <Route element={<FourZeroFourPage />} />
        </Routes>
      </IntlProvider>
    )
  }
}

RoutesComponent.propTypes = {
  globalData: PropTypes.objectOf(PropTypes.any).isRequired
}

export default RoutesComponent
